import React, { useState } from 'react'
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import CriarContent from '../../criar/criar';
import { Button, Checkbox, IcoAdd, IcoBin, IcoPencil, IcoUpload, List, LoadingContent } from '../../../../components';
import { useEffect } from 'react';
import { listarEstabelecimentosVerificar, listarGruposEstabelecimentos, listarMeusProjetosEmpresas, listarMeusProjetosEstabelecimentos, removeListMeuProjetoTab, removerEstabelecimento } from '../../redux/meusProjetosActions';
import { listarPaises, modalOpen } from '../../../../layout/redux/layoutActions';
import Add from './add';
import Importar from './importar';
import Verificar from './verificar';
import Grupos from './grupos';
import { IF, filterList, history } from '../../../../helpers';
import { typeStatusCustom } from '../../redux/meusProjetos';
import ObterMais from '../../obterMais';


export default () => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const location = useLocation();
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const { meuProjeto, paginate } = useSelector(state=> state.meusProjetosState)
  const [form, setForm] = useState({selects: {}})
  const [infomodal, setInfomodal] = useState({selects: {}})
  const [estabelecimentos, setEstabelecimentos] = useState([])
  
    useEffect(()=> {
      if (idProjetos) {
        dispatch(listarMeusProjetosEmpresas({idProjeto: idProjetos}))
        dispatch(listarMeusProjetosEstabelecimentos({...meuProjeto.estabelecimentosPaginate, idProjeto: idProjetos}))
        dispatch(listarGruposEstabelecimentos(idProjetos))
        // dispatch(listarPaises(sistema.idioma))
      }
    }, [idProjetos])

    useEffect(()=> {
      const query = new URLSearchParams(location.search);
      const searchTerm = query.get('estabelecimentos');
      if (meuProjeto?.estabelecimentos?.length && searchTerm) {
        setEstabelecimentos(filterList(searchTerm, 'nomeEstabelecimento', meuProjeto.estabelecimentos))
      } else {
        setEstabelecimentos(meuProjeto.estabelecimentos)
      }
    }, [meuProjeto.estabelecimentos, history.location])

  const headerDefault = [
    {
      column: 'checkboxCustom',
      text: <Checkbox 
        name='all'
        checked={form.selects?.all}
        action={e=> handleCheckboxSelect({name: 'all', value: e.value})}
      />,
      className: 'actions',
    },
    {
      column: 'nomeEstabelecimento',
      text: nls.table.nome,
      className: '',
    },
    {
      column: 'statusCustom',
      text: nls.table.status,
      className: '',
    },
    {
      column: 'nomeEmpresa',
      text: nls.table.empresa,
      className: '',
    },
    {
      column: 'grupoEstabelecimento',
      text: nls.table.grupo,
      className: '',
    },
    {
      column: 'urlTripAdvisorEstabelecimento',
      text: 'TripAdvisor',
      className: '',
    },
    {
      column: 'endereco',
      text: nls.table.endereco,
      className: '',
    },
    {
      column: 'bairro',
      text: nls.table.bairro,
      className: '',
    },
    {
      column: 'cidade',
      text: nls.table.cidade,
      className: '',
    },
    {
      column: 'estado',
      text: nls.table.estado,
      className: '',
    },
    {
      column: 'cep',
      text: nls.table.cep,
      className: '',
    },
    {
      column: 'actions',
      text: '',
      className: 'actions',
    }
  ]

  const handleCheckboxSelect = e => {
    if (e.name === 'all') {
      let all = {all: e.value}
      meuProjeto.estabelecimentos.filter(f=> f.situacao !== 'AD').map(p=> {  all = {...all, [p.idEstabelecimento]: e.value} })
      setForm({...form, selects: all})
    } else {
      setForm({...form, selects: {...form.selects, [e.name]: e.value}})
    }
  }

  const selectedHas = Object.keys(form.selects).filter(p=> form.selects[p] ).length

  const handlePaginate = e => {
    dispatch(listarMeusProjetosEstabelecimentos({...meuProjeto.estabelecimentosPaginate, idProjeto: idProjetos, pageNumber: e }))
  }

  const handlePerPage = e => {
    dispatch(listarMeusProjetosEstabelecimentos({...meuProjeto.estabelecimentosPaginate, idProjeto: idProjetos, perPage: Number(e.value.id)}))
  }
  
  const handleAddModal = e => {
    if (e?.idEstabelecimento) {
      setInfomodal(e)
      dispatch(modalOpen('criarEstabelecimentosMeuProjeto'))
    } else if (sistema?.creditoConta?.creditoEstabelecimentos) {
      setInfomodal({})
      dispatch(modalOpen('criarEstabelecimentosMeuProjeto'))
    } else {
      dispatch(modalOpen('obter-mais'))
    }
  }
  
  const handleRemove = e => {
    const returnedHas = Object.keys(e).filter(f=> f !== 'all' && e[f] === true )
    // returnedHas.map(id=> {
    //   dispatch(removeListMeuProjetoTab({id, name: 'estabelecimentos'}))
    // })
    dispatch(removerEstabelecimento(returnedHas, idProjetos))
    handleCheckboxSelect({name: 'all', value: false})
  }

  const handleImportarModal = e => {
    if (sistema?.creditoConta?.creditoEstabelecimentos) {
      dispatch(modalOpen('importarEstabelecimentoMeuProjeto'))
    } else {
      dispatch(modalOpen('obter-mais'))
    }
  }

  const handleVerificarModal = e => {
    if (sistema.creditoConta?.creditoEstabelecimentos) {
      if (e.idEstabelecimento) {
        dispatch(listarEstabelecimentosVerificar(e.idEstabelecimento))
        setInfomodal(e)
      } else {
        setInfomodal({})
      }
      dispatch(modalOpen('verificarEstabelecimentosMeuProjeto'))
    } else {
      dispatch(modalOpen('obter-mais'))
    }
  }

  const statusTypes = e => {
    switch (e.status) {
      case 'procurando':
        return <span className='flag-warning'>Procurando estabelecimento</span>;
      case 'sucesso':
        return <span className='flag-info'>Verificação concluída</span>;
      default:
        return <span className='flag-primary' onClick={()=> handleVerificarModal(e)}>Realizar verificação</span>;
    }
  }

  const handleGruposModal = e => {
    dispatch(modalOpen('gruposEstabelecimentoMeuProjeto'))
  }
  
  return (
    <div className='box-meus-projetos-estabelecimentos'>
      <div className='box-meus-projetos-topinfo'>
        <h5>{nls.title}</h5>
        <div>
          <Button
            type='link'
            color='default'
            onClick={()=> handleRemove(form.selects)}
            disabled={selectedHas === 0? true:false}
          ><IcoBin /> {nls.btnExcluir}</Button>
          <Button
            color='primary'
            onClick={()=> handleGruposModal()}
          >{nls.btnGrupos}</Button>
            <Button
              color='default'
              onClick={()=> handleImportarModal()}
            ><IcoUpload /> {nls.btnImportar}</Button>
            <Button
              color='primary circle'
              onClick={()=> handleAddModal()}
            ><IcoAdd /> {nls.btnAdd}</Button>
        </div>
      </div>
      <LoadingContent
        show={false}
      >
        <List
          header={headerDefault}
          data={estabelecimentos}
          listCustom={e=> {
            const item = e
            item.latitudeLongitude = e.coordenadas
            item.checkboxCustom =  e.situacao !== 'AD'? <>
              <Checkbox 
                name={e.idEstabelecimento}
                checked={form.selects?.[e.idEstabelecimento]}
                action={c=> handleCheckboxSelect({name: e.idEstabelecimento, value: c.value})}
              />
            </> :null
            // item.statusCustom = e.statusEstabelecimento //statusTypes(e)
            const statusInfo = typeStatusCustom(e.statusEstabelecimento)
            item.statusCustom = <span 
              style={{cursor: 'pointer'}} 
              className={statusInfo?.class} 
              onClick={()=> e.statusBotao ? handleVerificarModal(e) : null}
            >{e.statusEstabelecimento}</span>
            item.actions = e.podeSerEditado? <>
              <Button
                type='link'
                color='default'
                onClick={()=> handleAddModal(e)}
              ><IcoPencil /> {nls.btnEditar}</Button>
            </> :null

            return item
          }}
          paginate={meuProjeto.estabelecimentosPaginate}
          actionPaginate={(e)=> handlePaginate(e)}
          handlePerPage={(e)=> handlePerPage(e)}
          nls={nls.paginacao}
        />
      </LoadingContent>
      <Add data={infomodal} />
      <Importar />
      <Verificar data={infomodal} />
      <Grupos />
      <ObterMais type='conteudoEstabelecimentos'/>
    </div>
  )
}
