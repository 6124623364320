import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Input, List, SelectDefault } from '../../components'
import { setUsuario } from './redux/usuariosActions'
import { listarMeusProjetos } from '../meusProjetos/redux/meusProjetosActions'


export default () => {
  const dispatch = useDispatch()
  const { sistema } = useSelector(state => state.layoutState)
  const { usuario, permissoes, erro } = useSelector(state => state.usuariosState)
  const { listMeusProjetos, paginate } = useSelector(state=> state.meusProjetosState)
  const nls = require(`./nls/${sistema.idioma}.json`)

  useEffect(()=> {
    if (usuario?.perfil?.id) {
      // popUsuariopermissionsRules(usuario?.perfil)
    }
  }, [usuario?.perfil])

  const popUsuariopermissionsRules = e => {
    let permissaoUser = permissoes?.map(permissao=> {
      return {
        ...permissao,
      }
    })
    if (e?.id === 1) {
      permissaoUser = permissoes?.map(permissao=> {
        return {
          ...permissao,
          podeVisualizar: true,
          podeEditar: permissao.permissaoEditarProjeto ? true:false
        }
      })
    }
    if (e?.id === 2) {
      permissaoUser = permissoes?.map(permissao=> {
        return {
          ...permissao,
          podeVisualizar: true,
          podeEditar: permissao.permissaoEditarProjeto ? true:false
        }
      })
    }
    if (e?.id === 3) {
      permissaoUser = permissoes?.map(permissao=> {
        return {
          ...permissao,
          podeVisualizar: false,
          podeEditar: false
        }
      })
    } 
    dispatch(setUsuario({name: 'permissoes', value: permissaoUser}))
  }  

  const headerDefault = [
    {
      column: 'nome',
      text: nls?.tableProjeto,
      className: '',
    },
    {
      column: 'visualizarCustom',
      text: nls?.tableVisualizar,
      className: 'actions',
    },
    {
      column: 'editarCustom',
      text: nls?.tableEditar,
      className: 'actions',
    }
  ]

  const handleChange = e => {
    dispatch(setUsuario(e))
    if (e.name === 'perfil') {
      popUsuariopermissionsRules(e.value)
    }
  }  
   
  const handleCheckboxSelect = e => {
    let permissaoUser = usuario.permissoes.map(p=> p.id === e.id ? {...p, ...e.name === 'podeEditar' && e.value ? {podeVisualizar: e.value,[e.name]: e.value}:{[e.name]: e.value} } : p)
    dispatch(setUsuario({name: 'permissoes', value: permissaoUser}))
  }  

  const listaPerfil = [
    // {id:1, name: nls.master},
    {id:2, name: nls.gerente},
    {id:3, name: nls.padrao}
  ]

  return (
    <div className='box-gerenciar-usuarios-add'>
      <h5>{nls?.subtitleDetalhes}</h5>
      <div className='colflex col2'>
        <Input
          name='email'
          label={nls?.formEmail}
          value={usuario?.email}
          placeholder={nls?.formEmailPH}
          action={(e) => handleChange(e)}
          disabled={usuario?.id?true:false}
          required={{
            erro,
            message: ''
          }}
        />
        <SelectDefault
          name='perfil'
          label={nls?.formPerfil}
          options={usuario.possiveisNovosPerfis?usuario.possiveisNovosPerfis:listaPerfil}
          selected={usuario?.perfil?usuario.perfil:{}}
          action={(e) => handleChange(e)}
          textCustom={nls.select}
          optionLabel='name'
          optionValue='id'
          required={{
            erro,
            message: ''
          }}
        />
      </div>
      <h5>{nls?.subtitlePermissaoUsuario}</h5>
      <div className='colflex'>
        <List
          header={headerDefault}
          data={usuario.permissoes}
          listCustom={e=> {
            const item = e
            
            item.editarCustom = <>
              <Checkbox 
                type='switch'
                name={e.id}
                checked={e?.podeEditar}
                action={c=> handleCheckboxSelect({id: e.id, name: 'podeEditar', value: c.value})}
                text={e?.podeEditar? nls.tableAtivado:nls.tableDesativado}
                disabled={usuario?.perfil?.id === 1 || !e.permissaoEditarProjeto || usuario?.perfil?.id === 2 ?true:false}
              />
            </>
            item.visualizarCustom = <>
              <Checkbox
                type='switch'
                name={e.id}
                checked={e?.podeVisualizar}
                action={c=> handleCheckboxSelect({id: e.id, name: 'podeVisualizar', value: c.value})}
                text={e?.podeVisualizar? nls.tableAtivado:nls.tableDesativado}
                disabled={e?.podeEditar || usuario?.perfil?.id === 1 || usuario?.perfil?.id === 2 ?true:false}
              />
            </>

            return item
          }}
          paginate={false?paginate:false}
          actionPaginate={(e)=> null}
          handlePerPage={(e)=> null}
          nls={nls.paginacao}
        />
      </div>
    </div>
  )
}