import React, { useState } from 'react'
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, IcoAdd, IcoBin, IcoPencil, IcoUpload, List, LoadingContent } from '../../../../components';
import { useEffect } from 'react';
import { listarGruposProdutos, listarMeusProjetosEmpresas, listarMeusProjetosProdutos, listarSubsegmentos, removeListMeuProjetoTab, removerProduto } from '../../redux/meusProjetosActions';
import { modalOpen } from '../../../../layout/redux/layoutActions';
import Add from './add';
import Importar from './importar';
import Grupos from './grupos';
import { IF, filterList, history } from '../../../../helpers';
import { typeStatusCustom } from '../../redux/meusProjetos';
import ObterMais from '../../obterMais';


export default () => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const location = useLocation();
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const { meuProjeto, paginate } = useSelector(state=> state.meusProjetosState)
  const [form, setForm] = useState({selects: {}})
  const [infomodal, setInfomodal] = useState({selects: {}})
  const [produtos, setProdutos] = useState([])

  useEffect(()=> {
    console.warn(meuProjeto, idProjetos, 'meuProjeto');
    if (idProjetos) {
      
      dispatch(listarMeusProjetosEmpresas({idProjeto: idProjetos}))
      dispatch(listarMeusProjetosProdutos({...meuProjeto.produtosPaginate, idProjeto: idProjetos}))
      dispatch(listarGruposProdutos(idProjetos))
      // dispatch(listarSubsegmentos({ProjetoId: idProjetos, SegmentoId: ''}))
    }
  }, [idProjetos])

  useEffect(()=> {
    if (meuProjeto?.projeto?.segmentoId) {
      dispatch(listarSubsegmentos({SegmentoId: meuProjeto.projeto.segmentoId}))
    }
  }, [meuProjeto.projeto])

    useEffect(()=> {
      const query = new URLSearchParams(location.search);
      const searchTerm = query.get('produtos');
      if (meuProjeto?.produtos?.length && searchTerm) {
        setProdutos(filterList(searchTerm, 'nomeProduto', meuProjeto.produtos))
      } else {
        setProdutos(meuProjeto?.produtos)
      }
    }, [meuProjeto.produtos, history.location])

  const headerDefault = [
    {
      column: 'checkboxCustom',
      text: <Checkbox 
        name='all'
        checked={form.selects?.all}
        action={e=> handleCheckboxSelect({name: 'all', value: e.value})}
      />,
      className: 'actions',
    },
    {
      column: 'nomeProduto',
      text: nls.table.nome,
      className: '',
    },
    {
      column: 'statusCuston',
      text: nls.table.status,
      sort: '',
      className: '',
    },
    {
      column: 'nomeEmpresa',
      text: 'Empresa',
      sort: '',
      className: '',
    },
    {
      column: 'subSegmento',
      text: 'Subsegmento',
      sort: '',
      className: '',
    },
    {
      column: 'grupoCustom',
      text: 'Grupo',
      className: '',
    },
    {
      column: 'urlAmazonCustom',
      text: 'Amazon',
      className: '',
    },
    {
      column: 'actions',
      text: '',
      className: 'actions',
    }
  ]

  const handleCheckboxSelect = e => {
    if (e.name === 'all') {
      let all = {all: e.value}
      meuProjeto.produtos.filter(f=> f.situacao !== 'AD').map(p=> {  all = {...all, [p.idProduto]: e.value} })
      setForm({...form, selects: all})
    } else {
      setForm({...form, selects: {...form.selects, [e.name]: e.value}})
    }
  }

  const selectedHas = Object.keys(form.selects).filter(p=> form.selects[p] ).length

  const handlePaginate = e => {
    console.warn(e, 'handlePaginate');
    
    dispatch(listarMeusProjetosProdutos({...meuProjeto.produtosPaginate, idProjeto: idProjetos, pageNumber: e}))
  }

  const handlePerPage = e => {
    console.warn(e, 'handlePerPage');
    
    dispatch(listarMeusProjetosProdutos({...meuProjeto.produtosPaginate, idProjeto: idProjetos, perPage: Number(e.value.id)}))
  }
  
  const handleAddModal = e => {
    if (e?.idProduto) {
      setInfomodal(e)
      dispatch(modalOpen('criarProdutoMeuProjeto'))
    } else if (sistema?.creditoConta?.creditoProdutos) {
      setInfomodal({})
      dispatch(modalOpen('criarProdutoMeuProjeto'))
    } else {
      dispatch(modalOpen('obter-mais'))
    }
  }
  
  const handleRemove = e => {
    const returnedHas = Object.keys(e).filter(f=> f !== 'all' && e[f] === true).map(f=> Number(f))
    dispatch(removerProduto(returnedHas, idProjetos))
    handleCheckboxSelect({name: 'all', value: false})
  }

  const handleImportarModal = e => {
    if (sistema?.creditoConta?.creditoProdutos) {
      dispatch(modalOpen('importarProdutoMeuProjeto'))
    } else {
      dispatch(modalOpen('obter-mais'))
    }
  }

  const handleGruposModal = e => {
    dispatch(modalOpen('gruposProdutoMeuProjeto'))
  }
  
  return (
    <div className='box-meus-projetos-produtos'>
      <div className='box-meus-projetos-topinfo'>
        <h5>{nls.title}</h5>
        <div>
          <Button
            type='link'
            color='default'
            onClick={()=> handleRemove(form.selects)}
            disabled={selectedHas === 0? true:false}
          ><IcoBin /> {nls.btnExcluir}</Button>
          <Button
            color='primary'
            onClick={()=> handleGruposModal()}
          >{nls.btnGrupos}</Button>
            <Button
              color='default'
              onClick={()=> handleImportarModal()}
            ><IcoUpload /> {nls.btnImportar}</Button>
            <Button
              color='primary circle'
              onClick={()=> handleAddModal()}
            ><IcoAdd /> {nls.btnAdd}</Button>
        </div>
      </div>
      {console.error(produtos, 'produtos')}
      <LoadingContent
          show={false}
        >
          <List
            header={headerDefault}
            data={produtos}
            listCustom={e=> {
              const item = e

              const statusInfo = typeStatusCustom(e.statusProduto)
              item.statusCuston = <span style={{cursor: 'pointer'}} className={statusInfo?.class}>{e.statusProduto}</span>
              // e.status?nls.ativo:nls.inativo

              item.grupoCustom = e.grupoProduto
              item.urlAmazonCustom = `${e.urlAmazonProduto.slice(0, 100)}${e.urlAmazonProduto.length>100?'...':''}`

              item.checkboxCustom = e.situacao !== 'AD'? <>
                <Checkbox 
                  name={e.idProduto}
                  checked={form.selects?.[e.idProduto]}
                  action={c=> handleCheckboxSelect({name: e.idProduto, value: c.value})}
                />
              </> :null
              item.actions = true? <>
                <Button
                  type='link'
                  color='default'
                  onClick={()=> handleAddModal(e)}
                ><IcoPencil /> {nls.btnEditar}</Button>
              </> :null

              return item
            }}
            paginate={meuProjeto.produtosPaginate}
            actionPaginate={(e)=> handlePaginate(e)}
            handlePerPage={(e)=> handlePerPage(e)}
            nls={nls.paginacao}
          />
        </LoadingContent>
        <Add data={infomodal} />
        <Importar />
        <Grupos />
        <ObterMais type='conteudoProdutos'/>
    </div>
  )
}
