import { AddAlert } from '../../../components';
import { history, paramsApi } from '../../../helpers';
import api from '../../../helpers/api';
import apiAuth from '../../../helpers/apiAuth';
import { modalAlertaInfo, modalClose } from '../../../layout/redux/layoutActions';

export const setSenhaVisivel = payload => ({
  type: 'SENHA_VISIVEL',
  payload
})

export const setChangeForm = payload => ({
  type: 'LOGIN_CHANGE_INFO',
  payload
})

export const setChangeEsqueci = payload => ({
  type: 'LOGIN_CHANGE_ESQUECI',
  payload
})

export const setChangeRecuperar = payload => ({
  type: 'LOGIN_CHANGE_RECUPERAR',
  payload
})

export const setError = payload => ({
  type: 'LOGIN_ERROR',
  payload
})

export const setChangeCadastro = payload => ({
  type: 'CADASTRAR_CHANGE_INFO',
  payload
})

export const setDisabledItem = payload => ({
  type: 'DISABLED_ITEM',
  payload
})

export const enviarEmailRecuperarSenha = ({params}) => {
  let paramsUrl = paramsApi({ 
    ...params
  })
  return async dispatch => {
    apiAuth.post(`/Auth/ForgetPassword${paramsUrl}`)
    .then(result => {
      dispatch(AddAlert('success', 'E-mail enviado com sucesso! veja sua caixa de e-mail.'))
      history.push('/login')
    })
    .catch(err => {
      const {message} = err.response.data
      console.error(err);
      dispatch(AddAlert('danger', message))
    });
  }
}

export const mudarSenhaRecuperarSenha = ({params}) => {
  let paramsUrl = paramsApi({
    ...params
  })
  return async dispatch => {
    apiAuth.post(`/Auth/ResetPassword`, params)
    .then(result => {
      dispatch(AddAlert('success', 'Nova senha cadastrada com sucesso!'))
      history.push('/login')
    })
    .catch(err => {
      const {message} = err.response.data
      console.error(err);
      dispatch(AddAlert('danger', message))
    });
  }
}

export const cadastrarUsuario = (e, nls, nlsPopup) => {
  if (e.Empresa) {
    return cadastrarCriarUsuario(e, nls, nlsPopup)
  } else {
    return cadastrarCriarUsuarioConvidado(e, nls, nlsPopup)
  }
}

export const cadastrarCriarUsuario = (e, nls, nlsPopup) => {
  const params = {
    nome: e.Nome,
    sobrenome: e.Sobrenome,
    celular: e.Celular,
    email: e.Email,
    paisId: e?.Pais?.idPais,
    nomeEmpresaQueTrabalha: e.Empresa,
    segmentoInteresseId: e?.Segmento?.id,
    senhaUsuario: e.Senha,
    confirmarSenhaUsuario: e.SenhaConfirma,
    aceitaReceberMsgs: e.novidades === true?true:false,
    IpCadastro: e.IpCadastro
  }
  
  return async dispatch => {
    api.post(`/Usuario/CriaConta`, params)
    .then(result => {
      console.log(result, 'result cadastrarCriarUsuario');
      dispatch(AddAlert('success', 'Usuario cadastrado com sucesso!'))
      
      dispatch(modalAlertaInfo({
        ...nlsPopup['confirmEmailsucesso'], 
        btnAction: ()=> {
          dispatch(modalAlertaInfo({ show:false }))
          history.push('/login')
        }, 
        textos: {
          email: `<strong>${e.Email}</strong>`,
          emailSuporte: `<strong>support@yellowtokens.com</strong>`
          // 'emailSuporte': `<Button type='link' color='primary' variant='outlined' onClick=${()=> handleSuport('support@yellowtokens.com')}>support@yellowtokens.com</Button>`
        }
      }))
    })
    .catch(err => {
      const {data} = err.response
      console.error(err);
      dispatch(AddAlert('danger', data ? data : nls.mensagem.errorCadastro))
    });
  }
}

export const cadastrarCriarUsuarioConvidado = (e, nls, nlsPopup) => {
  const params = {
    nome: e.Nome,
    sobrenome: e.Sobrenome,
    celular: e.Celular,
    email: e.Email,
    PaisId: e?.Pais?.idPais,
    SegmentoInteresseId: e?.Segmento?.id,
    senha: e.Senha,
    confirmarSenhaUsuario: e.SenhaConfirma,
    aceitaReceberMsgs: e.novidades === true?true:false,
    ipCadastro: e.IpCadastro
  }
  return async dispatch => {
    api.post(`/Usuario/ConvidadoCriaConta`, params)
    .then(result => {
      dispatch(AddAlert('success', 'Usuario cadastrado com sucesso!'))
      
      dispatch(modalAlertaInfo({
        ...nlsPopup['confirmEmailsucesso'], 
        btnAction: ()=> {
          dispatch(modalAlertaInfo({ show:false }))
          history.push('/login')
        }, 
        textos: {
          email: `<strong>${e.Email}</strong>`,
          emailSuporte: `<strong>support@yellowtokens.com</strong>`
          // 'emailSuporte': `<Button type='link' color='primary' variant='outlined' onClick=${()=> handleSuport('support@yellowtokens.com')}>support@yellowtokens.com</Button>`
        }
      }))
    })
    .catch(err => {
      const {data} = err.response
      console.error(err);
      dispatch(AddAlert('danger', data ? data : nls.mensagem.errorCadastro))
    });
  }
}


