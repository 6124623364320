import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Checkbox, IcoEye, IcoEyeBlocked, Input, Loading, MaskTelefoneInt, passwordHas, SelectDefault, validacaoForm, validarCampo, validarSenhaConfirmar, verificaValidacao } from '../../components'

import { cadastrarUsuario, setChangeCadastro, setDisabledItem, setError, setSenhaVisivel } from './redux/loginActions'
import './login.scss'
import { history, idiomaString } from '../../helpers'
import imgFrame from '../../layout/img/login/bg-2-front.png'
import imgLogo from '../../layout/img/logo-yellow.png'
import { getIp, listarEstados } from '../../layout/redux/layoutActions'

export default () => {
  const dispatch = useDispatch()
  const location = useLocation();
  const { load, sistema, listas, logIn } = useSelector(state=> state.layoutState)
  const { senhaVisivel, cadastro, erro, disabledItem } = useSelector(state=> state.loginState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../layout/nlsPopup/${sistema.idioma}.json`)

  useEffect(()=> {
    if (!logIn) { 
      dispatch([
        getIp()
      ])
    }
  },[])

  useEffect(()=> {
    const query = new URLSearchParams(location.search);
    const searchTerm = query.get('email');
    if (searchTerm) {
      dispatch(setDisabledItem({name: 'Email', value: true}))
      dispatch(setChangeCadastro({name: 'Email', value: searchTerm}))
    }
  }, [history.location])

  useEffect(()=> {
    if (sistema?.pais?.codigoPais && !cadastro?.Pais) { 
      handleChange({name: 'Pais', value: sistema.pais, pattern: 'select'})
    }
  },[sistema])

  const formRequired = {
    Nome: '',
    Sobrenome: '',
    // Email: 'email',
    Celular: '',
    Pais: 'select',
    Segmento: 'select',
    Senha: 'senha',
    SenhaConfirma: 'senha',
  }

  const handleChange = e => {
    dispatch(setChangeCadastro(e))
    const hasReq = Object.keys(formRequired).includes(e.name);

    if (hasReq) {
      const verify = validarCampo(e)
      
      let message = verify.message
      
      if (e.name === 'SenhaConfirma') {
        message = validarSenhaConfirmar({value: e.value, senha: cadastro.Senha})
      }

      dispatch(setError({...erro, [verify.name]: message}))
    }
  }

  const handleChangeBlur = e => {
    console.error(e, 'handleChangeBlur')
    const valid = validacaoForm({formValues: {...cadastro, [e.name]: e.value}, formRequired})
    const verify = verificaValidacao(valid)
    const senhasIguais = cadastro.Senha !== cadastro.SenhaConfirma ? false : true
    const termos = e.name === 'termos' ? e.value : cadastro.termos
    let valor = true
    if (verify.value && senhasIguais && termos) {
      valor = false
    }
    dispatch(setDisabledItem({name: 'cadastrar', value: valor}))
  }

  const handleSee = e => {
    dispatch(setSenhaVisivel(e))
  }

  const handleCriar = e => {
    const valid = validacaoForm({formValues: e, formRequired})
    dispatch(setError(valid))
    const verify = verificaValidacao(valid)
    const termos = e.termos

    if (e.Senha !== e.SenhaConfirma) {
      dispatch(setError({...erro, 'senhaConfirma': 'different'}))      
    }
    if (verify.value && e.Senha === e.SenhaConfirma && termos) {
      dispatch(cadastrarUsuario({...e, IpCadastro: sistema.getip.ipaddress}, nls, nlsPopup))
    }
  }

  const idiomaCustom = e => {
    e = e === 'en-US'?'en':e
    return e.toLowerCase()
  }

  return (
    <div className='box-login'>
      <Alert />
      <div className='box-login-container'>
        <div className='box-login-frame'>
          <img src={imgFrame} />
        </div>
        <div className='box-login-form'>
          <div className='box-login-content'>
            <div className='box-login-logo'>
              {/* <IcoLogo style={{width: '262px'}} /> */}
              <img src={imgLogo} />
            </div>
            <div className='box-login-info'>
              <h2>{nls?.cadastrar.title}</h2>
              {/* <h4>{nls?.cadastrar.subtitle}</h4> */}
              {/* <p></p> */}
            </div>
            <div className='colflex col2'>
              <Input
                // label={nls?.formNome}
                name='Nome'
                action={(e) => handleChange(e)}
                actionBlur={(e) => handleChangeBlur(e)}
                value={cadastro.Nome}
                placeholder={nls?.formNome}
                required={{
                  erro,
                  message: nls.mensagem.obrigatorio,
                  pattern: formRequired.Nome
                }}
              />
              <Input
                // label={nls?.formSobrenome}
                name='Sobrenome'
                action={(e) => handleChange(e)}
                actionBlur={(e) => handleChangeBlur(e)}
                value={cadastro.Sobrenome}
                placeholder={nls?.formSobrenome}
                required={{
                  erro,
                  message: nls.mensagem.obrigatorio,
                  pattern: formRequired.Sobrenome
                }}
              />
            </div>
            <div className='colflex col2'>
              <Input
                // label={nls?.formEmail}
                name='Email'
                action={(e) => handleChange(e)}
                actionBlur={(e) => handleChangeBlur(e)}
                value={cadastro.Email}
                placeholder={nls?.formEmail}
                disabled={disabledItem.Email}
                required={{
                  erro,
                  message: nls.mensagem,
                  pattern: formRequired.Email
                }}
              />
              <Input
                name='Celular'
                // label={nls?.formCelular}
                value={cadastro?.Celular}
                placeholder={nls?.formCelular}
                action={(e) => handleChange(e)}
                actionBlur={(e) => handleChangeBlur(e)}
                maxLength='20'
                required={{
                  erro,
                  message: nls.mensagem.obrigatorio,
                  pattern: formRequired.Celular
                }}
              />
            </div>
            <hr />
            <div className='colflex col2'>
              <SelectDefault
                name='Pais'
                // label={nls?.formPais}
                options={listas.paisesIdiomas[idiomaCustom(sistema.idioma)]}
                selected={cadastro?.Pais?cadastro?.Pais:''}
                action={(e) => handleChange(e)}
                actionBlur={(e) => handleChangeBlur(e)}
                textCustom={nls.selectPais}
                optionLabel='nomePais'
                optionValue='idPais'
                required={{
                  erro,
                  message: nls.mensagem.obrigatorio,
                  pattern: formRequired.Pais
                }}
              />
              <SelectDefault
                name='Segmento'
                // label={nls?.formSegmento}
                options={listas.segmentosIdiomas[idiomaCustom(sistema.idioma)]}
                selected={cadastro?.Segmento?cadastro?.Segmento:''}
                action={(e) => handleChange(e)}
                actionBlur={(e) => handleChangeBlur(e)}
                textCustom={nls.selectSegmento}
                optionLabel='nome'
                optionValue='id'
                required={{
                  erro,
                  message: nls.mensagem.obrigatorio,
                  pattern: formRequired.Segmento
                }}
              />
            </div>
            <hr />
            <div className='colflex col2'>
              <div>
                <Input
                  // label={nls?.formSenha}
                  name='Senha'
                  type={!senhaVisivel.Senha?'password':'text'}
                  action={(e) => handleChange(e)}
                  actionBlur={(e) => handleChangeBlur(e)}
                  value={cadastro.Senha}
                  placeholder={nls?.formSenha}
                  right={
                    <Button type='link' onClick={()=> handleSee({name: 'Senha', value: !senhaVisivel.Senha})}>
                      {!senhaVisivel.Senha ? <IcoEye />
                      : <IcoEyeBlocked />}
                    </Button>
                  }
                />
                <div className='regras-senha' dangerouslySetInnerHTML={{__html: passwordHas(cadastro.Senha)}}></div>
              </div>
              <div>
                <Input
                  // label={nls?.formSenhaConfirmar}
                  name='SenhaConfirma'
                  type={!senhaVisivel.SenhaConfirma?'password':'text'}
                  action={(e) => handleChange(e)}
                  actionBlur={(e) => handleChangeBlur(e)}
                  value={cadastro.SenhaConfirma}
                  placeholder={nls?.formSenhaConfirmar}
                  right={
                    <Button type='link' onClick={()=> handleSee({name: 'SenhaConfirma', value: !senhaVisivel.SenhaConfirma})}>
                      {!senhaVisivel.SenhaConfirma ? <IcoEye />
                      : <IcoEyeBlocked />}
                    </Button>
                  }
                  required={{
                    erro,
                    message: nls.mensagem,
                    pattern: formRequired.SenhaConfirma
                  }}
                />
              </div>
            </div>
            <div className=''>
              <Checkbox
                name='termos'
                checked={cadastro.termos}
                action={e=> {
                  handleChange(e)
                  handleChangeBlur(e)
                }}
                text={nls?.cadastrar.termos}
              />
            </div>
            <div className=''>
              <Checkbox
                name='novidades'
                checked={cadastro.novidades}
                action={e=> {
                  handleChange(e)
                }}
                text={nls?.cadastrar.novidades}
              />
            </div>
            <div className=''>
              <p>{nls?.cadastrar.novidadesInfo}</p>
            </div>
            <div className='box-login-action'>
              <div className='box-login-action-btns'>
                <Button
                  color='primary'
                  variant='outlined'
                  onClick={()=> history.push('/login')}
                >{nls?.btnCancelar}</Button>
                <Button
                  color='primary'
                  onClick={()=> handleCriar(cadastro)}
                  disabled={disabledItem.cadastrar}
                >{nls?.btnCriarConta}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {load? <Loading />:null}
    </div>
  )
}
